<template>
  <div>
    <div class="bg" style="padding-bottom: 150px">
      <el-row :gutter="20" style="margin: 150px 100px">
        <el-col :span="8" :offset="0" style="color: white">
          <div style="font-size: 30px; text-align: center; padding-top: 150px">
            <p style="margin: 0; font-weight: bolder">技术创新 科技兴邦</p>
            <p style="margin: 10px 0; font-size: 20px">
              竭诚为您提供趋近于完美的技术服务
            </p>
            <p
              style="
                margin: 10px 0;
                font-size: 20px;
                color: #4296d2;
                font-weight: bolder;
                cursor: pointer;
              "
            >
              了解更多>>
            </p>
          </div>
        </el-col>
        <el-col :span="16" :offset="0">
          <t-card>
            <el-row :gutter="20" style="padding: 50px 50px 50px 0">
              <el-col :span="12" :offset="0" style="text-align: center">
                <p style="font-size: 30px">扫码登录</p>
                <img
                  src="@/assets/qrcode.png"
                  alt=""
                  style="width: 200px; height: 200px"
                />
                <p>使用纳斯云APP或纳斯云小程序</p>
                <p>扫码登录更加快捷</p>
              </el-col>
              <el-col :span="12" :offset="0">
                <div
                  style="
                    display: flex;
                    justify-content: space-around;
                    color: #1476bf;
                    font-size: 20px;
                    font-weight: bolder;
                  "
                >
                  <p
                    class="loginText"
                    :class="{ active: isShow === 1 }"
                    @click="switchShow(1)"
                  >
                    账户密码登录
                  </p>
                  <p
                    class="loginText"
                    :class="{ active: isShow === 2 }"
                    @click="switchShow(2)"
                  >
                    手机号验证登录
                  </p>
                </div>
                <!-- 账号密码登录 -->
                <div v-show="isShow === 1">
                  <input type="username" placeholder="请输入用户名" />
                  <input
                    type="password"
                    placeholder="请输入密码"
                    style="margin-top: 20px"
                  />
                  <div
                    style="
                      text-align: center;
                      background-color: #1476bf;
                      color: white;
                      padding: 10px 20px;
                      font-size: 20px;
                      margin-top: 50px;
                    "
                  >
                    <span>登录</span>
                  </div>
                  <div>
                    <p style="float: left">忘记账户名</p>
                    <p
                      style="float: right; cursor: pointer"
                      @click="$router.push('/forget')"
                    >
                      忘记密码
                    </p>
                  </div>
                </div>
                <!-- 手机号验证登录 -->
                <div v-show="isShow === 2">
                  <input type="username" placeholder="请输入手机号码" />
                  <div class="verification-container" style="margin-top: 20px">
                    <input type="text" placeholder="请输入验证码" />
                    <button class="verification-button">获取验证码</button>
                  </div>
                  <div
                    style="
                      text-align: center;
                      background-color: #1476bf;
                      color: white;
                      padding: 10px 20px;
                      font-size: 20px;
                      margin-top: 50px;
                    "
                  >
                    <span>登录</span>
                  </div>
                  <div>
                    <p style="float: left">手机号码不再可用？</p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </t-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: 1,
    };
  },
  methods: {
    switchShow(show) {
      this.isShow = show;
    },
  },
  created() {},
};
</script>

<style>
.bg {
  position: relative;
  background-image: url("@/assets/GuangGu4.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* 调整这里的透明度和颜色 */
  z-index: 1;
}

.el-row {
  position: relative;
  z-index: 2; /* 确保内容显示在遮罩层之上 */
}

.shadowed-div {
  position: relative;
  width: 100vh;
  background-color: #fff;
  z-index: 10;
}

.shadowed-div::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 100vh;
  z-index: -1;
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
}

.loginText {
  color: black;
  position: relative;
  cursor: pointer;
  transition: color 0.3s ease;
}

.loginText::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s ease, transform 0.3s ease;
  transform: scaleX(0);
  transform-origin: center;
}

.loginText.active,
.loginText:hover {
  color: rgb(20, 118, 191);
}

.loginText.active::after,
.loginText:hover::after {
  background-color: rgb(20, 118, 191);
  transform: scaleX(1);
}

input {
  width: 100%;
  height: 35px;
  outline: none;
  border: 1px solid white;
  border-bottom: 1px solid black;
  font-size: 15px;
  transition: all 0.3s ease;
}

input:focus {
  border-bottom: 1px solid #4296d2;
}

.verification-container {
  display: flex;
  align-items: center;
}

.verification-container input {
  flex: 1;
  height: 35px;
  border: 1px solid white;
  border-bottom: 1px solid black;
  font-size: 15px;
  outline: none;
}

.verification-button {
  background-color: #1476bf;
  color: white;
  border: none;
  padding: 0 15px;
  height: 35px;
  cursor: pointer;
  font-size: 15px;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
}

.verification-button:hover {
  background-color: #0f5a8a;
}
</style>

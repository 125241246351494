<template>
  <div>
    <div>
      <!-- <img
        src="../assets/GuangGu.jpg"
        alt="GuangGu"
        style="width: 100%; height: 600px; filter: brightness(90%)"
      /> -->
      <!-- 视频部分 -->
      <video
        src="@/assets/video/video.mp4"
        style="
          width: 100%;
          height: 600px;
          object-fit: fill;
          filter: brightness(90%);
        "
        muted
        autoplay
        loop
      ></video>
      <div
        style="
          position: absolute;
          color: white;
          font-size: 40px;
          margin: -350px 0 0 100px;
          background-color: rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(10px);
          padding: 20px;
          border-radius: 20px;
        "
      >
        <span style="font-weight: bolder; font-size: 2rem">{{
          $t("home.title1")
        }}</span>
        <br />
        <span style="font-size: 30px; font-size: 1.5rem">{{
          $t("home.title2")
        }}</span>
      </div>
    </div>
    <div class="width">
      <el-row gutter="60">
        <el-col :xs="24" :lg="12">
          <img
            src="../assets/GuangGu3.jpg"
            alt="GuangGu3"
            style="width: 100%; border-radius: 20px"
          />
        </el-col>
        <el-col :xs="24" :lg="12">
          <span style="color: black; font-size: 40px">{{
            $t("home.companyOverview")
          }}</span>
          <el-divider></el-divider>
          <span style="line-height: 40px; color: #606266">{{
            $t("home.companyOverviewContent")
          }}</span>
          <div
            style="
              color: #1c3059;
              padding: 10px;
              border: 2px solid;
              border-radius: 10px;
              width: 80px;
              text-align: center;
              margin-top: 20px;
            "
          >
            <span>{{ $t("home.learnMore") }}</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="width productShow" style="text-align: center; color: black">
      <span style="font-size: 50px">{{ $t("home.globalOverview") }}</span>
      <br />
      <h3 style="margin-top: 20px">
        {{ $t("home.globalOverviewContent") }}
      </h3>
      <div>
        <!-- echart地图 -->
        <div
          id="echart_china"
          ref="echart_china"
          style="width: 100%; height: 700px"
        ></div>
        <!-- 备注 -->
        <div>
          <img src="@/assets/location.png" alt="" style="width: 20px" />
          <span>已开通</span>
          <img src="@/assets/location_blue.png" alt="" style="width: 20px" />
          <span>即将开通</span>
          <img src="@/assets/location_dark.png" alt="" style="width: 20px" />
          <span>待开通</span>
        </div>
      </div>
    </div>
    <div class="width">
      <div style="display: flex; align-items: center">
        <span style="font-size: 2.5rem">{{ $t("home.news") }}</span>
        <br />
        <h1
          style="
            font-size: 1.3rem;
            font-weight: 400;
            margin-left: 10px;
            margin-top: 21px;
            color: #666666;
          "
        >
          {{ $t("home.latestNews") }}
        </h1>
      </div>
      <el-row gutter="50" style="margin-top: 20px">
        <el-col :xs="24" :lg="12">
          <img
            src="../assets/GuangGu5.jpg"
            alt="GuangGu"
            style="width: 100%; border-radius: 10px"
          />
        </el-col>
        <el-col :xs="24" :lg="12">
          <div @click="$router.push('/newsdetail')">
            <el-row>
              <el-col span="4">
                <span style="font-size: 30px">{{ $t("home.newsDate1") }}</span>
                <el-divider direction="vertical" />
              </el-col>
              <el-col span="20">
                <span>{{ $t("home.newsContent1") }}</span>
              </el-col>
            </el-row>
          </div>
          <el-divider></el-divider>
          <div @click="$router.push('/newsdetail')">
            <el-row>
              <el-col span="4">
                <span style="font-size: 30px">{{ $t("home.newsDate2") }}</span>
                <el-divider direction="vertical" />
              </el-col>
              <el-col span="20">
                <span>{{ $t("home.newsContent2") }}</span>
              </el-col>
            </el-row>
          </div>
          <el-divider></el-divider>
          <div @click="$router.push('/newsdetail')">
            <el-row>
              <el-col span="4">
                <span style="font-size: 30px">{{ $t("home.newsDate3") }}</span>
                <el-divider direction="vertical" />
              </el-col>
              <el-col span="20">
                <span>{{ $t("home.newsContent3") }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import chinamap from "echarts/map/json/china.json";

export default {
  data() {
    return {
      myChart: null,
    };
  },
  mounted() {
    // 1. 创建一个 ECharts 实例，返回 echartsInstance，不能在单个容器上初始化多个 ECharts 实例
    this.myChart = this.$echarts.init(this.$refs.echart_china);
    this.showScatterInGeo();
  },
  methods: {
    /*
        geo:地理坐标系组件( https://echarts.apache.org/zh/option.html#geo)
        地理坐标系组件用于地图的绘制，支持在地理坐标系上绘制散点图
      */
    showScatterInGeo() {
      // 2. 注册可用的地图，只在 geo 组件或者map图表类型中使用
      this.$echarts.registerMap("china", chinamap); //用导入的json文件注册一个name:china的地图组件
      // 3. 设置图表 option
      var option = {
        geo: {
          type: "map",
          map: "china",
          label: {
            // label 设置文本标签的显示格式，去掉不影响显示地图
            normal: {
              color: "#000000",
              show: true, //显示省份名称
            },
          },
          itemStyle: {
            normal: {
              areaColor: "#c8cfdb", // 地图区域的背景色
              borderColor: "white", // 地图边框颜色
              borderWidth: 0.3, // 地图边框宽度
            },
            emphasis: {
              areaColor: "darkgrey", // 鼠标悬浮时地图区域的背景色
            },
          },
        },
        series: [
          {
            name: "在地图中显示散点图",
            type: "scatter",
            coordinateSystem: "geo", //设置坐标系为 geo
            data: [
              // 这里为每个标注点设置不同的图标
              {
                name: "安徽",
                value: [117.283042, 31.86119],
                symbol: `image://${require("@/assets/location.png")}`,
                symbolSize: 30,
              },
              {
                name: "湖北",
                value: [113.298572, 31.484355],
                symbol: `image://${require("@/assets/location.png")}`,
                symbolSize: 30,
              },
              {
                name: "广东",
                value: [113.280637, 24.125178],
                symbol: `image://${require("@/assets/location_blue.png")}`,
                symbolSize: 25,
              },
              {
                name: "北京",
                value: [116.407526, 39.90403],
                symbol: `image://${require("@/assets/location_dark.png")}`,
                symbolSize: 20,
              },
              {
                name: "上海",
                value: [121.473701, 31.230416],
                symbol: `image://${require("@/assets/location_dark.png")}`,
                symbolSize: 20,
              },
              {
                name: "浙江",
                value: [120.153576, 30.287459],
                symbol: `image://${require("@/assets/location_dark.png")}`,
                symbolSize: 20,
              },
              {
                name: "重庆",
                value: [106.504962, 29.533155],
                symbol: `image://${require("@/assets/location_dark.png")}`,
                symbolSize: 20,
              },
            ],
          },
        ],
      };
      // 4. myChart.setOption
      this.myChart.setOption(option);
    },
  },
};
</script>

<style>
.productShow {
  position: relative; /* 确保伪元素相对于#productShow定位 */
  padding: 80px;
}

.productShow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-image: url("../assets/GuangGu4.jpg"); */
  background-color: #f8f9fa;
  background-size: cover;
  /* filter: brightness(50%); 控制背景图片的亮度 */
  z-index: -1; /* 确保背景图片在其他内容之下 */
}

.el-card__body {
  padding: 0;
}

#cardText {
  padding: 20px;
}

#cardText:hover {
  padding: 20px;
  background-color: #1c3059;
  color: white;
  transition: background-color 0.3s ease; /* 添加过渡效果 */
}

.width {
  padding: 5rem;
}

@media screen and (max-width: 768px) {
  .width {
    padding: 1.5rem;
  }
}
</style>

<template>
  <div>
    <div id="newsDetail">
      <h1
        style="
          color: white;
          font-size: 50px;
          margin: 200px 0 0 50px;
          font-weight: 400;
        "
      >
        {{ $t("newsDetail.newsDetail") }}
      </h1>
      <br />
      <h1
        style="
          color: white;
          font-size: 15px;
          margin: 0 0 0 50px;
          font-weight: 400;
        "
      >
        {{ $t("newsDetail.newslocation") }}
      </h1>
    </div>
    <div class="newsStyle">
      <!-- 新闻日期 -->
      <h4 style="padding: 10px">日期：2024年6月16日</h4>
      <!-- 新闻标题 -->
      <h1 style="text-align: center; margin: 20px">
        武汉温拓网络科技有限公司已于2024年某年某月某日正式更名为武汉市温拓股份有限公司
      </h1>
      <div style="text-align: center">
        <!-- 新闻配图 -->
        <img src="@/assets/GuangGu5.jpg" alt="" class="newsImage" />
      </div>
      <!-- 新闻正文 -->
      <div>
        <h1 style="margin: 20px; font-size: 25px; font-weight: 400">
          这里是新闻正文这里是新闻正文这里是新闻正文这里是新闻正文这里是新闻正文这里是新闻正文这里是新闻正文这里是新闻正文这里是新闻正文这里是新闻正文这里是新闻正文这里是新闻正文这里是新闻正文这里是新闻正文这里是新闻正文
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
#newsDetail {
  position: relative; /* 确保伪元素相对于#contectus定位 */
  padding: 80px;
  height: 500px;
}

#newsDetail::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../assets/GuangGu10.jpg");
  background-size: cover;
  filter: brightness(50%); /* 控制背景图片的亮度 */
  z-index: -1; /* 确保背景图片在其他内容之下 */
}

.newsStyle {
  padding: 30px 140px 50px 140px;
}

.newsImage {
  width: 80%;
  height: 30rem;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .newsStyle {
    padding: 1.5rem;
  }
  .newsImage {
    width: 100%;
    height: 20rem;
    border-radius: 20px;
  }
}
</style>

<template>
  <div>
    <div>
      <div id="productShow">
        <h1
          style="
            color: white;
            font-size: 50px;
            margin: 200px 0 0 50px;
            font-weight: 400;
          "
        >
          {{ $t("projectShow.title") }}
        </h1>
        <br />
        <h1
          style="
            color: white;
            font-size: 15px;
            margin: 0 0 0 50px;
            font-weight: 400;
          "
        >
          {{ $t("projectShow.currentPosition") }}
        </h1>
      </div>
    </div>
    <div style="padding: 50px">
      <h1 style="text-align: center; font-size: 40px; font-weight: 400">
        {{ $t("projectShow.title") }}
      </h1>
      <el-divider></el-divider>
      <el-row gutter="20">
        <el-col :xs="24" :lg="8"
          ><el-card
            ><img
              src="../assets/projectShow.png"
              alt="GuangGu"
              style="width: 100%"
            />
            <div id="textCard">
              <h1>{{ $t("projectShow.project.title") }}</h1>
            </div></el-card
          ></el-col
        >
        <el-col :xs="24" :lg="8"
          ><el-card
            ><img
              src="../assets/projectShow.png"
              alt="GuangGu"
              style="width: 100%"
            />
            <div id="textCard">
              <h1>{{ $t("projectShow.project.title") }}</h1>
            </div></el-card
          ></el-col
        >
        <el-col :xs="24" :lg="8"
          ><el-card
            ><img
              src="../assets/projectShow.png"
              alt="GuangGu"
              style="width: 100%"
            />
            <div id="textCard">
              <h1>{{ $t("projectShow.project.title") }}</h1>
            </div></el-card
          ></el-col
        >
      </el-row>
      <el-row gutter="20" style="margin-top: 20px">
        <el-col :xs="24" :lg="8"
          ><el-card
            ><img
              src="../assets/projectShow.png"
              alt="GuangGu"
              style="width: 100%"
            />
            <div id="textCard">
              <h1>{{ $t("projectShow.project.title") }}</h1>
            </div></el-card
          ></el-col
        >
        <el-col :xs="24" :lg="8"
          ><el-card
            ><img
              src="../assets/projectShow.png"
              alt="GuangGu"
              style="width: 100%"
            />
            <div id="textCard">
              <h1>{{ $t("projectShow.project.title") }}</h1>
            </div></el-card
          ></el-col
        >
        <el-col :xs="24" :lg="8"
          ><el-card
            ><img
              src="../assets/projectShow.png"
              alt="GuangGu"
              style="width: 100%"
            />
            <div id="textCard">
              <h1>{{ $t("projectShow.project.title") }}</h1>
            </div></el-card
          ></el-col
        >
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
#productShow {
  position: relative; /* 确保伪元素相对于#productShow定位 */
  padding: 80px;
  height: 500px;
}

#productShow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../assets/GuangGu8.jpg");
  background-size: cover;
  filter: brightness(50%); /* 控制背景图片的亮度 */
  z-index: -1; /* 确保背景图片在其他内容之下 */
}

#textCard {
  font-size: 15px;
  padding: 20px;
  text-align: center;
}

h1 {
  margin: 0;
}

#textCard:hover {
  background-color: #1c3059;
  color: white;
  transition: background-color 0.3s ease; /* 添加过渡效果 */
}
</style>

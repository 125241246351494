<template>
  <div id="app">
    <el-container>
      <el-header
        :style="{
          position: 'fixed',
          top: '0',
          color: headerColor,
          textDecoration: 'none',
          backgroundColor: headerBackgroundColor,
          backdropFilter: 'blur(10px)',
          height: '80px',
          width: '100%',
          zIndex: 1000,
          boxShadow: headerShadow,
        }"
      >
        <el-row class="header" :style="{ position: 'relative' }">
          <el-col :xs="4" :lg="4" style="margin-top: 20px">
            <img :src="headerImg" alt="logo" style="width: 10rem" />
          </el-col>
          <el-col :xs="0" :lg="16">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              background-color="transparent"
              text-color="white"
              active-text-color="white"
              @select="handleSelect"
              style="
                display: flex;
                justify-content: space-between;
                color: #303136;
              "
            >
              <el-menu-item index="1" @click="$router.push('/')">
                <router-link
                  to="/"
                  :style="{ color: headerColor, textDecoration: 'none' }"
                  @click.native="activeRoute = '/'"
                  >{{ $t("header.home") }}</router-link
                ></el-menu-item
              >
              <el-menu-item index="2" @click="$router.push('/brand')">
                <router-link
                  to="/brand"
                  :style="{ color: headerColor, textDecoration: 'none' }"
                  @click.native="activeRoute = '/brand'"
                  >{{ $t("header.brand") }}</router-link
                ></el-menu-item
              >

              <el-menu-item index="3" @click="$router.push('/product')">
                <router-link
                  to="/product"
                  :style="{ color: headerColor, textDecoration: 'none' }"
                  @click.native="activeRoute = '/product'"
                  >{{ $t("header.products") }}</router-link
                ></el-menu-item
              >
              <el-menu-item index="4">
                <span
                  :style="{ color: headerColor, textDecoration: 'none' }"
                  @mouseover="changeStyle(1)"
                >
                  {{ $t("header.news") }}
                </span>
              </el-menu-item>
              <el-menu-item index="5" @click="$router.push('/news')">
                <span
                  :style="{ color: headerColor, textDecoration: 'none' }"
                  @mouseover="changeStyle(2)"
                  >{{ $t("header.cooperate") }}</span
                >
              </el-menu-item>
              <el-menu-item index="6" @click="$router.push('/contectus')">
                <router-link
                  to="/contectus"
                  :style="{ color: headerColor, textDecoration: 'none' }"
                  @click.native="activeRoute = '/contectus'"
                  >{{ $t("header.contact") }}</router-link
                >
              </el-menu-item>
              <el-menu-item>
                <el-popover placement="top-start" trigger="hover" width="200">
                  <div style="line-height: 25px; padding: 5px">
                    <div>
                      <span style="color: #909399">中国站</span>
                      <span class="rightText" @click="switchLanguage('zh-cn')"
                        >简体中文</span
                      >
                    </div>
                    <div
                      style="border-bottom: 1px solid #efefef; margin: 5px 0px"
                    ></div>
                    <div>
                      <span style="color: #909399; cursor: pointer"
                        >International</span
                      >
                      <span class="rightText" @click="switchLanguage('en-us')"
                        >English</span
                      >
                      <br />
                      <span class="rightText" @click="switchLanguage('zh-tw')"
                        >繁体中文</span
                      >
                    </div>
                  </div>
                  <span
                    style="font-size: 15px; margin-right: 20px"
                    :style="{ color: headerColor, textDecoration: 'none' }"
                    slot="reference"
                    >{{ selectedCountry.alt }}</span
                  >
                </el-popover>
              </el-menu-item>
            </el-menu>
          </el-col>
          <el-col
            :xs="19"
            :lg="4"
            style="line-height: 63px; font-size: 20px; display: flex"
          >
            <i class="el-icon-search" v-if="width > 768"></i>
            <!-- 登录注册按钮 -->
            <div
              class="hide"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 15px;
              "
            >
              <div class="btn" @click="$router.push('/login')">
                <span style="color: white">登录</span>
              </div>
              <div
                class="btn"
                @click="$router.push('/reg')"
                style="
                  margin-left: 10px;
                  background-color: #dcdfe6;
                  color: black;
                "
              >
                <span style="color: black">注册</span>
              </div>
            </div>
          </el-col>
          <el-col :xs="1" :lg="0">
            <i
              class="el-icon-d-arrow-right"
              @click="drawer = true"
              style="font-size: 1.5rem; margin-top: 25px"
            ></i>
          </el-col>
        </el-row>
      </el-header>

      <el-main style="padding: 0">
        <div
          class="showCard"
          v-show="show === true"
          @mouseover="changeStyle(1)"
          @mouseout="changeStyleBack(1)"
        >
          <el-row>
            <el-col
              :span="5"
              :offset="0"
              style="background-color: #f0f2f5; padding: 30px; height: 80vh"
            >
              <span>云学院</span>
              <br />
              <span style="color: #85868a"
                >面向开发者的技术学习、交流实践平台。</span
              >
            </el-col>
            <el-col :span="19" :offset="0">
              <el-row :gutter="20" style="padding: 50px">
                <el-col :span="8" :offset="0" style="display: flex"
                  ><i class="el-icon-upload" style="font-size: 60px"></i>
                  <div style="margin-top: 10px">
                    <span>测试文字</span><br /><span>测试描述文字</span>
                  </div></el-col
                >
                <el-col :span="8" :offset="0" style="display: flex"
                  ><i class="el-icon-upload" style="font-size: 60px"></i>
                  <div style="margin-top: 10px">
                    <span>测试文字</span><br /><span>测试描述文字</span>
                  </div></el-col
                >
                <el-col :span="8" :offset="0" style="display: flex"
                  ><i class="el-icon-upload" style="font-size: 60px"></i>
                  <div style="margin-top: 10px">
                    <span>测试文字</span><br /><span>测试描述文字</span>
                  </div></el-col
                >
              </el-row>
              <el-row :gutter="20" style="padding: 50px">
                <el-col :span="8" :offset="0" style="display: flex"
                  ><i class="el-icon-upload" style="font-size: 60px"></i>
                  <div style="margin-top: 10px">
                    <span>测试文字</span><br /><span>测试描述文字</span>
                  </div></el-col
                >
                <el-col :span="8" :offset="0" style="display: flex"
                  ><i class="el-icon-upload" style="font-size: 60px"></i>
                  <div style="margin-top: 10px">
                    <span>测试文字</span><br /><span>测试描述文字</span>
                  </div></el-col
                >
                <el-col :span="8" :offset="0" style="display: flex"
                  ><i class="el-icon-upload" style="font-size: 60px"></i>
                  <div style="margin-top: 10px">
                    <span>测试文字</span><br /><span>测试描述文字</span>
                  </div></el-col
                >
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div
          class="showCard"
          v-show="show1 === true"
          @mouseover="changeStyle(2)"
          @mouseout="changeStyleBack(2)"
        >
          <el-row>
            <el-col
              :span="5"
              :offset="0"
              style="background-color: #f0f2f5; padding: 30px; height: 80vh"
            >
              <span>合作与生态</span>
              <br />
              <span style="color: #85868a"
                >亿速云始终保持着开放的态度，发 挥自身多年的行业能力积累，为生
                态和行业伙伴赋能。</span
              >
            </el-col>
            <el-col :span="19" :offset="0">
              <el-row :gutter="20" style="padding: 50px">
                <el-col :span="8" :offset="0" style="display: flex"
                  ><i class="el-icon-upload" style="font-size: 60px"></i>
                  <div style="margin-top: 10px">
                    <span>测试文字</span><br /><span>测试描述文字</span>
                  </div></el-col
                >
                <el-col :span="8" :offset="0" style="display: flex"
                  ><i class="el-icon-upload" style="font-size: 60px"></i>
                  <div style="margin-top: 10px">
                    <span>测试文字</span><br /><span>测试描述文字</span>
                  </div></el-col
                >
                <el-col :span="8" :offset="0" style="display: flex"
                  ><i class="el-icon-upload" style="font-size: 60px"></i>
                  <div style="margin-top: 10px">
                    <span>测试文字</span><br /><span>测试描述文字</span>
                  </div></el-col
                >
              </el-row>
            </el-col>
          </el-row>
        </div>
        <!-- 手机端页面菜单栏 -->
        <el-drawer :visible.sync="drawer" :direction="direction">
          <div>
            <el-row
              style="
                line-height: 70px;
                font-size: 15px;
                text-align: center;
                font-size: 1rem;
              "
            >
              <el-col span="24">
                <span>
                  <router-link
                    to="/"
                    style="color: black; text-decoration: none"
                    >{{ $t("header.home") }}</router-link
                  >
                </span>
              </el-col>
              <el-col span="24">
                <span>
                  <router-link
                    to="/brand"
                    style="color: black; text-decoration: none"
                    >{{ $t("header.brand") }}</router-link
                  >
                </span>
              </el-col>
              <el-col span="24">
                <span>
                  <router-link
                    to="/product"
                    style="color: black; text-decoration: none"
                    >{{ $t("header.products") }}</router-link
                  ></span
                >
              </el-col>
              <el-col span="24">
                <span>
                  <router-link
                    to="/news"
                    style="color: black; text-decoration: none"
                    >{{ $t("header.news") }}</router-link
                  ></span
                >
              </el-col>
              <el-col span="24">
                <span>
                  <router-link
                    to="/contectus"
                    style="color: black; text-decoration: none"
                    >{{ $t("header.contact") }}</router-link
                  ></span
                >
              </el-col>
            </el-row>
          </div>
        </el-drawer>
        <router-view />
      </el-main>
      <el-footer
        class="phonefooter"
        style="
          background-color: #303136;
          padding: 70px;
          height: 100%;
          color: #85868a;
        "
      >
        <el-row
          gutter="20"
          style="display: flex; justify-content: center; margin: 0 40px 0 40px"
        >
          <el-col :xs="12" :lg="5">
            <img src="./assets/logo_white.png" alt="logo" style="width: 80%" />
            <p>{{ $t("footer.address") }}</p>
            <p>{{ $t("footer.email") }}</p>
            <p>{{ $t("footer.phone") }}</p>
          </el-col>
          <el-col :xs="0" :lg="16">
            <el-row>
              <el-col span="6">
                <span style="color: white">{{ $t("footer.brand.title") }}</span>
                <p>{{ $t("footer.brand.menu1") }}</p>
                <p>{{ $t("footer.brand.menu2") }}</p>
                <p>{{ $t("footer.brand.menu3") }}</p>
                <p>{{ $t("footer.brand.menu4") }}</p>
              </el-col>
              <el-col span="6">
                <span style="color: white">{{ $t("footer.products") }}</span>
                <p>{{ $t("product.vue2company") }}</p>
                <p>{{ $t("product.vue2manager") }}</p>
                <p>{{ $t("product.vue3manager") }}</p>
              </el-col>
              <el-col span="6">
                <span style="color: white">{{ $t("footer.news") }}</span>
                <p>{{ $t("news.companynews") }}</p>
                <p>{{ $t("news.industrynews") }}</p>
              </el-col>
              <el-col span="6">
                <span style="color: white">{{ $t("footer.contact") }}</span>
                <p>{{ $t("contact.contact") }}</p>
                <p>{{ $t("contact.onlinemessage") }}</p>
                <p>{{ $t("contact.joinus") }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="0" :lg="3">
            <img src="./assets/qr-code.jpg" alt="qrcode" />
            <p>{{ $t("footer.qrcode") }}</p>
          </el-col>
          <el-col
            :xs="12"
            :lg="0"
            style="text-align: center; line-height: 60px"
          >
            <span
              ><router-link
                to="/brand"
                style="color: #85868a; text-decoration: none"
                >{{ $t("footer.brand.title") }}</router-link
              ></span
            >
            <br />
            <span
              ><router-link
                to="/product"
                style="color: #85868a; text-decoration: none"
                >{{ $t("footer.products") }}</router-link
              ></span
            >
            <br />
            <span
              ><router-link
                to="/news"
                style="color: #85868a; text-decoration: none"
                >{{ $t("footer.news") }}</router-link
              ></span
            >
            <br />
            <span
              ><router-link
                to="/contectus"
                style="color: #85868a; text-decoration: none"
                >{{ $t("footer.contact") }}</router-link
              ></span
            >
          </el-col>
        </el-row>
        <div style="margin: 0 20px 0 20px">
          <el-divider></el-divider>
          {{ $t("footer.copyright") }}
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      show1: false,
      drawer: false,
      // activeRoute: "/",
      activeIndex: "1",
      direction: "rtl",
      headerColor: "white", // 初始颜色
      btnColor: "white",
      headerBackgroundColor: "rgba(255, 255, 255, 0.2)", // 初始背景颜色
      headerImg: require("@/assets/logo_white.png"),
      headerShadow: "rgba(0, 0, 0, 0.75) 0px 0px 0px 0px",
      selectedCountry: {
        src: require("@/assets/CN.png"),
        alt: "中国站",
      },
      isScrolled: false, // 新增变量
    };
  },
  components: {},
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    switchLanguage(lang) {
      console.log("Switching language to:", lang);
      this.$i18n.locale = lang;
      console.log(lang);
      switch (lang) {
        case "zh-cn":
          this.selectedCountry.src = require("@/assets/CN.png");
          this.selectedCountry.alt = "中国站";
          break;
        case "zh-tw":
          this.selectedCountry.src = require("@/assets/TW.png");
          this.selectedCountry.alt = "國際站";
          break;
        case "en-us":
          this.selectedCountry.src = require("@/assets/US.png");
          this.selectedCountry.alt = "International";
          break;
      }
    },
    changeStyle(e) {
      if (e === 1) {
        this.show = true;
      } else {
        this.show1 = true;
      }
      console.log("鼠标移入");
      this.headerColor = "#1c3059"; // 改变颜色
      this.headerBackgroundColor = "white"; // 改变背景颜色为半透明黑色
      this.headerImg = require("@/assets/logo_black.png");
      this.headerShadow = "rgba(0, 0, 0, 0.75) 0px 0px 5px 0px";
      document.querySelector(".el-menu--horizontal").classList.add("scrolled");
    },
    changeStyleBack(e) {
      if (e === 1) {
        this.show = false;
      } else {
        this.show1 = false;
      }
      console.log("鼠标移出");
      this.show = false;
      this.headerColor = "white"; // 否则保持白色
      this.headerBackgroundColor = "rgba(255, 255, 255, 0.2)"; // 背景颜色恢复
      this.headerImg = require("@/assets/logo_white.png");
      this.headerShadow = "rgba(0, 0, 0, 0.75) 0px 0px 0px 0px";
      document
        .querySelector(".el-menu--horizontal")
        .classList.remove("scrolled");
    },
    handleScroll() {
      if (window.scrollY > 50) {
        // 当滚动超过50px时改变颜色
        this.headerColor = "#1c3059"; // 改变颜色
        this.headerBackgroundColor = "white"; // 改变背景颜色为半透明黑色
        this.headerImg = require("@/assets/logo_black.png");
        this.headerShadow = "rgba(0, 0, 0, 0.75) 0px 0px 5px 0px";
        document
          .querySelector(".el-menu--horizontal")
          .classList.add("scrolled");
      } else {
        this.headerColor = "white"; // 否则保持白色
        this.headerBackgroundColor = "rgba(255, 255, 255, 0.2)"; // 背景颜色恢复
        this.headerImg = require("@/assets/logo_white.png");
        this.headerShadow = "rgba(0, 0, 0, 0.75) 0px 0px 0px 0px";
        document
          .querySelector(".el-menu--horizontal")
          .classList.remove("scrolled");
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid white !important;
}
.scrolled > .el-menu-item.is-active {
  border-bottom: 4px solid #1c3059 !important;
}

.el-header {
  transition: background-color 0.5s ease; /* 添加过渡效果 */
}

@media screen and (max-width: 768px) {
  .header {
    margin: 0 !important;
  }

  .phonefooter {
    padding: 30px 10px 30px 10px !important;
  }
}

.header {
  margin: 0 100px 0 100px;
}

.el-menu.el-menu--horizontal {
  border-bottom: solid 0px !important;
}

/* 更改鼠标划过背景色字体色及borer显示 */
.el-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
  backdropfilter: blur(10px);
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 60px;
  line-height: 60px;
  border-bottom: 2px solid transparent;
  background-color: transparent !important;
  /* color: #909399; */
}

.el-menu--horizontal {
  background-color: white;
}

.menuText {
  color: #666666;
  font-weight: 400;
  margin: 0;
  transition: all 0.5s ease;
}

.menuText:hover {
  color: #1c3059;
  font-weight: 400;
}

.btn {
  background-color: #4296d2;
  font-size: 15px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.btn:hover {
  background-color: #1c3059;
  color: white;
}

.rightText {
  float: right;
  transition: all 0.3s ease;
  cursor: pointer;
}

.rightText:hover {
  color: #1c3059;
}

@media screen and (max-width: 768px) {
  .hide {
    display: none !important;
  }
}

.el-popover {
  border-radius: 10px !important;
  /* 其他你需要的样式 */
}

.showCard {
  background-color: white;
  width: 100%;
  height: 80vh;
  transition: all 0.3s ease;
  position: fixed;
  z-index: 999;
  margin-top: 80px;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 5px 0px;
}
</style>

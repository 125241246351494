import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import echarts from "echarts";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router/router.js";
import TDesign from "tdesign-vue";
import i18n from "./locales/i18n.js";

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(TDesign);

Vue.prototype.$http = axios;
Vue.prototype.$echarts = echarts;

i18n.locale = "zh-cn";

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div>
    <!-- header占位 -->
    <div style="background-color: rgb(48, 49, 54); height: 80px" />
    <!-- 主体部分 -->
    <div
      style="
        height: 500px;
        padding: 50px 0px 0px 0px;
        text-align: center;
        overflow: hidden;
      "
      v-show="isShow === 1"
    >
      <div style="margin-bottom: 20px">
        <i
          class="el-icon-arrow-left"
          style="position: absolute; left: 0; margin-left: 80px"
          >返回登录</i
        >
        <span style="font-size: 30px">找回密码</span>
      </div>
      <div style="height: 100%; background-color: #f8f8f8; width: 100%">
        <el-form label-width="200px" style="padding: 50px 350px">
          <el-form-item label="请输入您的用户名或手机号码">
            <div style="display: flex">
              <input v-model="username" type="text" class="inputClass" />

              <el-popover
                placement="top-start"
                title="找回密码提示"
                width="200"
                trigger="hover"
                content="此处输入为您的登录用户名，如无法提供，输入手机号码亦可"
              >
                <img
                  src="@/assets/ask.png"
                  alt=""
                  style="width: 35px; height: 35px; margin-left: 10px"
                  slot="reference"
                />
              </el-popover>
            </div>
          </el-form-item>
        </el-form>
        <div
          class="fbtn"
          v-loading.fullscreen.lock="fullscreenLoading"
          @click="nextStep()"
        >
          下一步：验证身份
        </div>
      </div>
    </div>
    <!-- 手机号验证部分 -->
    <div
      style="
        height: 500px;
        padding: 50px 0px 0px 0px;
        text-align: center;
        overflow: hidden;
      "
      v-show="isShow === 2"
    >
      <div style="margin-bottom: 20px">
        <i
          class="el-icon-arrow-left"
          style="position: absolute; left: 0; margin-left: 80px"
          @click="backStep()"
          >返回上一步</i
        >
        <span style="font-size: 30px">验证账号所有权</span>
      </div>
      <div style="height: 100%; background-color: #f8f8f8; width: 100%">
        <p style="color: #606266; padding-top: 30px">
          验证码已经发送到您的手机192****6064
        </p>
        <el-form label-width="200px" style="padding: 50px 350px">
          <el-form-item label="请输入验证码">
            <div style="display: flex">
              <input
                v-model="capcha"
                type="text"
                class="inputClass"
                style="width: 400px"
              />
            </div>
          </el-form-item>
        </el-form>
        <div
          class="fbtn"
          v-loading.fullscreen.lock="fullscreenLoading"
          @click="nextStep()"
        >
          下一步：修改密码
        </div>
      </div>
    </div>
    <!-- 修改密码 -->
    <div
      style="
        height: 500px;
        padding: 50px 0px 0px 0px;
        text-align: center;
        overflow: hidden;
      "
      v-show="isShow === 3"
    >
      <div style="margin-bottom: 20px">
        <i
          class="el-icon-arrow-left"
          style="position: absolute; left: 0; margin-left: 80px"
          @click="backStep()"
          >返回上一步</i
        >
        <span style="font-size: 30px">修改密码</span>
      </div>
      <div style="height: 100%; background-color: #f8f8f8; width: 100%">
        <el-form label-width="200px" style="padding: 50px 350px">
          <el-form-item label="请输入您的新密码">
            <div style="display: flex">
              <input
                v-model="password"
                type="password"
                class="inputClass"
                style="width: 400px"
              />
            </div>
          </el-form-item>
          <el-form-item label="请确认您的新密码">
            <div style="display: flex">
              <input
                v-model="checkPassword"
                type="password"
                class="inputClass"
                style="width: 400px"
              />
            </div>
          </el-form-item>
        </el-form>
        <div
          class="fbtn"
          v-loading.fullscreen.lock="fullscreenLoading"
          @click="nextStep('login')"
        >
          下一步：返回登录
        </div>
      </div>
    </div>
    <!-- 步骤条 -->
    <div style="background-color: #f8f8f8; margin: -100px 0 50px 0">
      <el-steps :active="active" align-center>
        <el-step title="步骤1" description="选择需要找回帮助的账号"></el-step>
        <el-step title="步骤2" description="验证账户所有权"></el-step>
        <el-step title="步骤3" description="修改密码"></el-step>
        <el-step title="步骤4" description="成功后返回再次登录"></el-step>
      </el-steps>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      isShow: 1,
      active: 1,
      capcha: "",
      password: "",
      checkPassword: "",
      fullscreenLoading: false,
    };
  },
  methods: {
    nextStep(e) {
      this.fullscreenLoading = true;
      if (e === "login") {
        setTimeout(() => {
          this.$message.success("密码修改成功！即将登录页面");
          setTimeout(() => {
            this.$router.push({
              path: "/login",
            });
          }, 2000);
        }, 2000);
      } else {
        setTimeout(() => {
          this.isShow = this.isShow + 1;
          this.active = this.active + 1;
          this.fullscreenLoading = false;
        }, 3000);
      }
    },
    backStep() {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.isShow = this.isShow - 1;
        this.active = this.active - 1;
        this.fullscreenLoading = false;
      }, 3000);
    },
  },
  created() {},
};
</script>

<style>
.inputClass {
  border: 1px solid #909399 !important;
}

.fbtn {
  display: inline-block;
  background-color: #4296d2;
  color: white;
  padding: 10px 80px;
  transition: all 0.2s ease;
}

.fbtn:hover {
  background-color: rgb(0, 94, 161);
}
</style>

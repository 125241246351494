import Vue from "vue";
import Router from "vue-router";
import HomeComponent from "@/components/HomeComponent.vue";
import BrandIntroduction from "@/components/BrandIntroduction.vue";
import ProductIntroduction from "@/components/ProductIntroduction.vue";
import NewsInfomation from "@/components/NewsInfomation.vue";
import ContectUS from "@/components/ContectUs.vue";
import NewsDetail from "@/components/NewsDetail.vue";
import JoinUs from "@/components/JoinUs.vue";
import LoginPage from "@/components/LoginPage.vue";
import RegPage from "@/components/RegPage.vue";
import ForgetPw from "@/components/ForgetPw.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    { path: "/", component: HomeComponent },
    { path: "/brand", component: BrandIntroduction },
    { path: "/product", component: ProductIntroduction },
    { path: "/news", component: NewsInfomation },
    { path: "/contectus", component: ContectUS },
    { path: "/newsdetail", component: NewsDetail },
    { path: "/joinus", component: JoinUs },
    {
      path: "/login",
      component: LoginPage,
    },
    {
      path: "/reg",
      component: RegPage,
    },
    {
      path: "/forget",
      component: ForgetPw,
    },
  ],
});

<template>
  <div>
    <div id="JoinUs">
      <h1
        style="
          color: white;
          font-size: 50px;
          margin: 200px 0 0 50px;
          font-weight: 400;
        "
      >
        {{ $t("joinUs.joinUs") }}
      </h1>
      <br />
      <h1
        style="
          color: white;
          font-size: 15px;
          margin: 0 0 0 50px;
          font-weight: 400;
        "
      >
        {{ $t("joinUs.joinLocation") }}
      </h1>
    </div>
    <div class="newsStyle">
      <!-- 新闻日期 -->
      <h4 style="padding: 10px">
        发布招聘日期：2024年6月16日 <br />
        招聘负责人：张三
      </h4>
      <!-- 新闻标题 -->
      <h1 style="text-align: center; margin: 20px">招募岗位：PHP工程师</h1>
      <!-- 新闻正文 -->
      <div>
        <h1 style="margin: 20px; font-size: 25px; font-weight: 400">
          要求：拥有PHP项目经验，懂得使用Laravel，ThinkPHP等后端框架，懂得使用MySQL，Redis等数据库，拥有一年以上工作经验或接受专科以上实习生
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
#JoinUs {
  position: relative; /* 确保伪元素相对于#contectus定位 */
  padding: 80px;
  height: 500px;
}

#JoinUs::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../assets/GuangGu11.jpg");
  background-size: cover;
  filter: brightness(50%); /* 控制背景图片的亮度 */
  z-index: -1; /* 确保背景图片在其他内容之下 */
}

@media screen and (max-width: 768px) {
}
</style>

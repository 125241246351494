<template>
  <div>
    <div id="brandShow">
      <h1
        style="
          color: white;
          font-size: 50px;
          margin: 200px 0 0 50px;
          font-weight: 400;
        "
      >
        {{ $t("brand1.aboutUs") }}
      </h1>
      <br />
      <h1
        style="
          color: white;
          font-size: 15px;
          margin: 0 0 0 50px;
          font-weight: 400;
        "
      >
        {{ $t("brand1.currentPosition") }}
      </h1>
    </div>
    <t-affix :offset-top="top" :offset-bottom="0" ref="affix">
      <div style="background-color: #f7f7f7; display: flex; z-index: 9999">
        <div
          class="margin"
          :style="{
            backgroundColor:
              selectedSection === 'overview' ? '#1c3059' : '#f7f7f7',
            color: selectedSection === 'overview' ? 'white' : 'black',
            padding: '20px',
          }"
          @click="selectSection('overview')"
        >
          <span>{{ $t("brand1.corporateOverview") }}</span>
        </div>
        <div
          :style="{
            backgroundColor:
              selectedSection === 'history' ? '#1c3059' : '#f7f7f7',
            color: selectedSection === 'history' ? 'white' : 'black',
            padding: '20px',
          }"
          @click="selectSection('history')"
        >
          <span>{{ $t("brand1.developmentHistory") }}</span>
        </div>
        <div
          :style="{
            backgroundColor:
              selectedSection === 'honor' ? '#1c3059' : '#f7f7f7',
            color: selectedSection === 'honor' ? 'white' : 'black',
            padding: '20px',
          }"
          @click="selectSection('honor')"
        >
          <span>{{ $t("brand1.qualificationsAndHonors") }}</span>
        </div>
        <div
          :style="{
            backgroundColor:
              selectedSection === 'culture' ? '#1c3059' : '#f7f7f7',
            color: selectedSection === 'culture' ? 'white' : 'black',
            padding: '20px',
          }"
          @click="selectSection('culture')"
        >
          <span>{{ $t("brand1.companyCulture") }}</span>
        </div>
      </div>
    </t-affix>
    <div id="overview" style="padding: 100px">
      <el-row gutter="100">
        <el-col :xs="24" :lg="14">
          <span style="font-size: 30px">{{
            $t("brand1.corporateOverview")
          }}</span>
          <br />
          <h1
            style="
              font-size: 20px;
              font-weight: 400;
              line-height: 50px;
              color: #666666;
            "
          >
            {{ $t("brand1.corporateOverviewDescription") }}
          </h1>
        </el-col>
        <el-col :xs="24" :lg="10">
          <img
            src="../assets/GuangGu6.jpg"
            alt="GuangGu"
            style="width: 100%; border-radius: 10px"
          />
        </el-col>
      </el-row>
    </div>
    <div id="history" style="padding: 100px; background-color: #fafafa">
      <h1 style="font-size: 40px; text-align: center; font-weight: 400">
        {{ $t("brand1.developmentHistory") }}
      </h1>
      <el-divider />
      <el-row :gutter="20">
        <el-col :xs="24" :lg="12">
          <img
            src="../assets/GuangGu7.jpg"
            alt="GuangGu"
            style="width: 100%; border-radius: 10px"
          />
        </el-col>
        <el-col :xs="24" :lg="12">
          <t-timeline :layout="direction" mode="same">
            <t-timeline-item label="2020-09-10">{{
              $t("brand1.timeline.event1")
            }}</t-timeline-item>
            <t-timeline-item label="2023-06-20">{{
              $t("brand1.timeline.event2")
            }}</t-timeline-item>
            <t-timeline-item label="2024-04-01">{{
              $t("brand1.timeline.event3")
            }}</t-timeline-item>
            <t-timeline-item label="2024-10-01">{{
              $t("brand1.timeline.event4")
            }}</t-timeline-item>
          </t-timeline>
        </el-col>
      </el-row>
    </div>
    <div id="honor" style="padding: 100px; background-color: #f2f2f2">
      <h1 style="font-size: 40px; text-align: center; font-weight: 400">
        {{ $t("brand1.qualificationsAndHonorsTitle") }}
      </h1>
      <el-divider />
      <el-row :gutter="20">
        <el-col :xs="12" :lg="6">
          <el-card>
            <img
              src="../assets/Certificate.jpeg"
              alt="GuangGu"
              style="width: 100%"
            />
            <h4 style="font-weight: 400; text-align: center">
              {{ $t("brand1.certificationDescription") }}
            </h4>
          </el-card>
        </el-col>
        <el-col :xs="12" :lg="6">
          <el-card>
            <img
              src="../assets/Certificate.jpeg"
              alt="GuangGu"
              style="width: 100%"
            />
            <h4 style="font-weight: 400; text-align: center">
              {{ $t("brand1.certificationDescription") }}
            </h4>
          </el-card>
        </el-col>
        <el-col :xs="12" :lg="6">
          <el-card>
            <img
              src="../assets/Certificate.jpeg"
              alt="GuangGu"
              style="width: 100%"
            />
            <h4 style="font-weight: 400; text-align: center">
              {{ $t("brand1.certificationDescription") }}
            </h4>
          </el-card>
        </el-col>
        <el-col :xs="12" :lg="6">
          <el-card>
            <img
              src="../assets/Certificate.jpeg"
              alt="GuangGu"
              style="width: 100%"
            />
            <h4 style="font-weight: 400; text-align: center">
              {{ $t("brand1.certificationDescription") }}
            </h4>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div id="culture" style="padding: 80px; background-color: #f2f2f2">
      <h1>企业文化预留内容</h1>
      <h1>
        武汉市温拓股份有限公司是一个非常牛逼的公司武汉市温拓股份有限公司是一个非常牛逼的公司武汉市温拓股份有限公司是一个非常牛逼的公司武汉市温拓股份有限公司是一个非常牛逼的公司
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      top: 80,
      selectedSection: "", // 当前选中的部分
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    selectSection(section) {
      // 直接更新 selectedSection 的值
      this.selectedSection = section;
      // 根据部分滚动到对应位置
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleScroll() {
      const overview = document.getElementById("overview");
      const history = document.getElementById("history");
      const honor = document.getElementById("honor");
      const culture = document.getElementById("culture");
      if (this.isInViewport(overview)) {
        this.selectedSection = "overview";
      } else if (this.isInViewport(history)) {
        this.selectedSection = "history";
      } else if (this.isInViewport(honor)) {
        this.selectedSection = "honor";
      } else if (this.isInViewport(culture)) {
        this.selectedSection = "culture";
      }
    },
    isInViewport(element) {
      if (!element) return false;
      const rect = element.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const windowWidth =
        window.innerWidth || document.documentElement.clientWidth;
      return (
        rect.top <= windowHeight &&
        rect.left <= windowWidth &&
        rect.bottom >= 0 &&
        rect.right >= 0
      );
    },
  },
};
</script>

<style>
#brandShow {
  position: relative; /* 确保伪元素相对于#brandShow定位 */
  padding: 80px;
  height: 500px;
}

#brandShow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../assets/GuangGu4.jpg");
  background-size: cover;
  filter: brightness(50%); /* 控制背景图片的亮度 */
  z-index: -1; /* 确保背景图片在其他内容之下 */
}

.margin {
  margin-left: 100px;
}

@media screen and (max-width: 768px) {
  .margin {
    margin-left: 0;
  }
}

.padding {
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .margin {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  #overview,
  #history,
  #honor,
  #culture {
    padding: 20px !important;
  }
}
</style>

<template>
  <div>
    <div id="contectus">
      <h1
        style="
          color: white;
          font-size: 50px;
          margin: 200px 0 0 50px;
          font-weight: 400;
        "
      >
        {{ $t("contectUS.title") }}
      </h1>
      <br />
      <h1
        style="
          color: white;
          font-size: 15px;
          margin: 0 0 0 50px;
          font-weight: 400;
        "
      >
        {{ $t("contectUS.currentPosition") }}
      </h1>
    </div>
    <div class="padding">
      <h1 style="font-size: 30px; font-weight: 400">
        {{ $t("contectUS.company") }}
      </h1>
      <h1
        style="
          font-size: 17px;
          font-weight: 400;
          margin-top: 15px;
          color: #666666;
        "
      >
        {{ $t("contectUS.companyInfo") }}
      </h1>
      <el-row gutter="20" style="margin-top: 40px">
        <el-col span="12">
          <span>{{ $t("contectUS.address") }}</span>
        </el-col>
        <el-col span="6">{{ $t("contectUS.email") }}</el-col>
        <el-col span="6">{{ $t("contectUS.phone") }}</el-col>
      </el-row>
      <div id="map" style="width: 100%; height: 400px; margin-top: 30px"></div>
    </div>
    <div class="padding1">
      <el-row :gutter="60">
        <el-col :xs="24" :lg="12">
          <img src="../assets/GuangGu7.jpg" alt="GuangGu" style="width: 100%" />
        </el-col>
        <el-col :xs="24" :lg="12" style="text-align: center">
          <h1 style="font-weight: 300; font-size: 30px">
            {{ $t("contectUS.message") }}
          </h1>
          <h1
            style="
              font-weight: 400;
              font-size: 17px;
              margin-top: 15px;
              color: #666666;
            "
          >
            {{ $t("contectUS.messageInfo") }}
          </h1>
          <el-form>
            <el-form-item :label="$t('contectUS.input1')">
              <el-input></el-input>
            </el-form-item>
            <el-form-item :label="$t('contectUS.input2')">
              <el-input></el-input>
            </el-form-item>
            <el-form-item :label="$t('contectUS.input3')">
              <el-input></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="width: 100%">{{
                $t("contectUS.button")
              }}</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="padding1" style="background-color: #f1f1f1">
      <h1 style="font-weight: 300; font-size: 30px">
        {{ $t("contectUS.joinTitle") }}
      </h1>
      <h1
        style="
          font-weight: 400;
          font-size: 17px;
          color: #666666;
          margin-top: 10px;
        "
      >
        {{ $t("contectUS.joinInfo") }}
        <el-row :gutter="20" style="margin-top: 20px">
          <el-col
            class="margin1"
            :xs="24"
            :lg="8"
            v-for="(item, index) in $t('contectUS.joinus')"
            :key="index"
          >
            <div @click="$router.push('/joinus')">
              <t-card :title="item.title" :bordered="false" hover-shadow>
                {{ item.info }}
              </t-card>
            </div>
          </el-col>
        </el-row>
      </h1>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
export default {
  data() {
    return {};
  },
  mounted() {
    window._AMapSecurityConfig = {
      securityJsCode: "2e311b2210d90680947b3a762fea0153",
    };

    const script = document.createElement("script");
    script.src = "https://webapi.amap.com/loader.js";
    script.onload = () => {
      AMapLoader.load({
        key: "d245f4ff714b490254c3de6d284897c9",
        version: "2.0",
      })
        .then((AMap) => {
          const map = new AMap.Map("map", {
            center: [114.415721, 30.500515], // 经纬度坐标，例如武汉市的经纬度
            zoom: 18, // 缩放级别，数字越大地图越远
          });

          // 获取国际化资源文件中的文本内容
          const titleText = this.$t("contectUS.company");

          // 添加点标记
          const marker = new AMap.Marker({
            position: [114.415721, 30.500515], // 标记的经纬度坐标
            title: titleText, // 标记的标题，鼠标悬停时显示
          });
          marker.setMap(map); // 将标记添加到地图上

          // 添加文字标记
          const textMarker = new AMap.Text({
            text: titleText, // 文字标记的内容
            position: [114.415721, 30.500515], // 文字标记的经纬度坐标
            style: {
              "background-color": "#009cf9",
              "border-color": "#fff",
              "border-width": 1,
              "border-style": "solid",
              padding: "5px 10px",
              color: "#fff",
              "font-size": "14px",
            },
          });
          textMarker.setMap(map); // 将文字标记添加到地图上
        })
        .catch((e) => {
          console.error(e);
        });
    };
    document.body.appendChild(script);
  },
};
</script>

<style>
#contectus {
  position: relative; /* 确保伪元素相对于#contectus定位 */
  padding: 80px;
  height: 500px;
}

#contectus::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../assets/contectUs.jpg");
  background-size: cover;
  filter: brightness(90%); /* 控制背景图片的亮度 */
  z-index: -1; /* 确保背景图片在其他内容之下 */
}

.padding {
  padding: 120px;
}

.padding1 {
  padding: 50px 120px 50px 120px;
}

@media screen and (max-width: 768px) {
  .padding {
    padding: 20px;
  }
  .padding1 {
    padding: 20px;
  }
  .margin1 {
    margin: 10px 0 0 0;
  }
}
</style>

<template>
  <div>
    <div id="news">
      <h1
        style="
          color: white;
          font-size: 50px;
          margin: 200px 0 0 50px;
          font-weight: 400;
        "
      >
        {{ $t("newsInfomation.title") }}
      </h1>
      <br />
      <h1
        style="
          color: white;
          font-size: 15px;
          margin: 0 0 0 50px;
          font-weight: 400;
        "
      >
        {{ $t("newsInfomation.currentPosition") }}
      </h1>
    </div>
    <div id="padding">
      <el-row :gutter="40" id="margin">
        <el-col :xs="24" :lg="14">
          <div id="newsImage">
            <h1 style="color: white; padding: 18rem 0 0 30px">
              <span style="color: #999999">{{
                $t("newsInfomation.news.date1")
              }}</span>
              <br />
              {{ $t("newsInfomation.news.title1") }}
            </h1>
          </div>
        </el-col>
        <el-col :xs="24" :lg="10">
          <div style="background-color: #fafafa" class="margintop">
            <img
              src="../assets/GuangGu5.jpg"
              alt="GuangGu"
              style="width: 100%; height: 250px"
            />
            <div id="newsText">
              <span style="color: #999999">{{
                $t("newsInfomation.news.date2")
              }}</span>
              <p>{{ $t("newsInfomation.news.title2") }}</p>
              <p>
                {{ $t("newsInfomation.news.title2Info") }}
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="40" id="margin">
        <el-col :xs="24" :lg="8"
          ><div
            style="background-color: #fafafa; height: 400px"
            class="margintop"
          >
            <img
              src="../assets/GuangGu5.jpg"
              alt="GuangGu"
              style="width: 100%; height: 250px"
            />
            <div style="padding: 10px 20px 10px 20px">
              <span style="color: #999999">{{
                $t("newsInfomation.news.date2")
              }}</span>
              <p>{{ $t("newsInfomation.news.title2") }}</p>
              <p>
                {{ $t("newsInfomation.news.title2Info") }}
              </p>
            </div>
          </div></el-col
        >
        <el-col :xs="24" :lg="8"
          ><div
            style="background-color: #fafafa; height: 400px"
            class="margintop"
          >
            <img
              src="../assets/GuangGu5.jpg"
              alt="GuangGu"
              style="width: 100%; height: 250px"
            />
            <div style="padding: 10px 20px 10px 20px">
              <span style="color: #999999">{{
                $t("newsInfomation.news.date2")
              }}</span>
              <p>{{ $t("newsInfomation.news.title2") }}</p>
              <p>
                {{ $t("newsInfomation.news.title2Info") }}
              </p>
            </div>
          </div></el-col
        >
        <el-col :xs="24" :lg="8"
          ><div
            style="background-color: #fafafa; height: 400px"
            class="margintop"
          >
            <img
              src="../assets/GuangGu5.jpg"
              alt="GuangGu"
              style="width: 100%; height: 250px"
            />
            <div style="padding: 10px 20px 10px 20px">
              <span style="color: #999999">{{
                $t("newsInfomation.news.date2")
              }}</span>
              <p>{{ $t("newsInfomation.news.title2") }}</p>
              <p>
                {{ $t("newsInfomation.news.title2Info") }}
              </p>
            </div>
          </div></el-col
        >
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
#news {
  position: relative; /* 确保伪元素相对于#news定位 */
  padding: 80px;
  height: 500px;
}

#news::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../assets/GuangGu9.jpg");
  background-size: cover;
  filter: brightness(50%); /* 控制背景图片的亮度 */
  z-index: -1; /* 确保背景图片在其他内容之下 */
}

#newsImage {
  position: relative;
  width: 100%;
  height: 400px;
}

#newsImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.8)
    ),
    url("../assets/projectShow.png");
  background-size: cover;
  z-index: -1; /* 确保背景图片在其他内容之下 */
}

#newsText {
  padding: 10px 20px 10px 20px;
  height: 100%;
  transition: background-color 0.5s ease; /* 添加过渡效果 */
}

#newsText:hover {
  padding: 10px 20px 10px 20px;
  height: 100%;
  background-color: darkgrey;
  color: white;
  transition: background-color 0.5s ease; /* 添加过渡效果 */
}

#padding {
  padding: 50px;
}

#margin {
  margin: 30px 30px 0 30px;
}

@media screen and (max-width: 768px) {
  #padding {
    padding: 10px;
  }
  #margin {
    margin: 0;
  }
  .margintop {
    margin-top: 10px;
  }
}
</style>
